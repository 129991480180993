import { useMemo } from "react";
import { BandwidthProfileMode, ConnectOptions } from "twilio-video";
import { Perspective } from "../types";
import { isMobile } from "react-device-detect";

export const useRecommendedConnectionProfile = (
  perspective: Perspective,
  mode: BandwidthProfileMode
) =>
  useMemo(() => {
    const connectionProfile: ConnectOptions = {
      insights: false, // Bandaid from Twilio support for freezing DOM: Remove after upgrading twilio-video.js past 2.26.0
      preferredAudioCodecs: ["opus"],
      preferredVideoCodecs: [
        "VP9",
        { codec: "VP8", simulcast: perspective === Perspective.presenter },
      ],
      maxAudioBitrate: isMobile ? 16000 : 32000,
      networkQuality: { local: 1, remote: 1 },
      audio: {
        echoCancellation: true,
        autoGainControl: false,
        latency: 0,
        noiseSuppression: true,
        sampleRate: 48000,
        sampleSize: isMobile ? 16 : 32,
      },
      video: {
        //aspectRatio: 1.7777777778, // 16:9, saving the processor a ms of calculating time
        frameRate: isMobile ? 24 : 30, // For mobile browsers, limit the maximum outgoing frame rate to 24 fps
        height: 1080 /*{
          ideal: isMobile ? 720 : 1080,
          max: isMobile ? 1080 : 2160,
          min: 720,
        },*/,
        width: 1920 /*{
          ideal: isMobile ? 1280 : 1920,
          max: isMobile ? 1920 : 3840,
          min: 1280,
        },*/,
      },
      bandwidthProfile: {
        video: {
          clientTrackSwitchOffControl: "manual",
          contentPreferencesMode: "manual",
          mode: mode,
        },
      },
    };

    // For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
    if (isMobile && connectionProfile?.bandwidthProfile?.video) {
      connectionProfile!.bandwidthProfile!.video!.maxSubscriptionBitrate = 2500000;
    }

    return connectionProfile;
  }, [perspective, mode]);
