import React, { FunctionComponent, Fragment, useEffect } from "react";
import {
  RemoteParticipant as RemoteVideoParticipant,
  RemoteVideoTrack,
} from "twilio-video";
import { VideocamOff } from "@mui/icons-material";
import { Perspective } from "../../types";
import { useTracks } from "../../hooks/useTracks";
import DisabledVideoOverlay from "./DisabledVideoOverlay";
import { useAudioOutput } from "../../hooks";

interface HTMLAudioElementWithSink extends HTMLAudioElement {
  setSinkId: (sinkId: string) => Promise<void>;
  readonly sinkId: string;
}

type Props = {
  enlargePreview: boolean;
  isPresenter?: boolean;
  noCmp?: boolean;
  participant: RemoteVideoParticipant;
  perspective: Perspective;
  volume?: number;
};

const RemoteParticipant: FunctionComponent<Props> = (props) => {
  const {
    enlargePreview,
    isPresenter = false,
    participant,
    perspective,
    volume = 1.0,
  } = props;

  const { muted: audioOutputMuted, deviceId: selectedAudioOutput } =
    useAudioOutput();

  // If this participant object is the presenter or the app itself is the presenter
  const shouldDisplayVideo =
    isPresenter || perspective === Perspective.presenter;

  const { audioRef, isVideoEnabled, videoRef, videoTracks } = useTracks(
    participant,
    shouldDisplayVideo
  );

  const { sid } = participant;

  useEffect(() => {
    const [videoTrack] = videoTracks as RemoteVideoTrack[];

    if (videoTrack) {
      videoTrack.switchOn();

      const renderDimensions = {
        width: perspective === Perspective.presenter ? 1920 : 1280,
        height: perspective === Perspective.presenter ? 1080 : 720,
      };

      videoTrack.setContentPreferences({
        renderDimensions: renderDimensions,
      });

      console.log(
        `Render dimensions for ${sid} were set to:\n${JSON.stringify(
          renderDimensions
        )}`
      );
    }
  }, [perspective, sid, videoTracks]);

  // Volume change hook
  useEffect(() => {
    if (audioRef.current && perspective === Perspective.participant) {
      audioRef.current.volume = volume;

      console.log(`Volume for ${sid} was set to ${volume}.`);
    }
  }, [audioRef, perspective, sid, volume]);

  // Audio output device change hook
  useEffect(() => {
    const audioEl = audioRef.current as HTMLAudioElementWithSink | null;
    if (audioEl && typeof selectedAudioOutput === "string") {
      (async () => {
        try {
          if (!audioEl.setSinkId) {
            throw new Error("setSinkId doesn't exist in HTMLAudioElement.");
          }

          await audioEl.setSinkId(selectedAudioOutput);
          console.log(`Audio is being played on ${selectedAudioOutput}`);
        } catch (err) {
          console.warn("Failed to switch audio output device.", err);
        }
      })();
    }
  }, [audioRef, selectedAudioOutput]);

  // Audio output muted state change hook
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.muted = audioOutputMuted;
    }
  }, [audioRef, audioOutputMuted]);

  return (
    <Fragment>
      {shouldDisplayVideo ? (
        <div
          className={
            enlargePreview
              ? "hidden"
              : perspective === Perspective.participant
              ? "remote-participant-container"
              : "fullscreen"
          }
          key={`${sid}-av-container`}
        >
          <video
            autoPlay={true}
            data-testid="remote-video"
            ref={videoRef}
            style={{
              aspectRatio: "16 / 9",
              backgroundColor: "#000000",
              maxHeight: "100%",
              width: "100%",
            }}
          />

          {!isVideoEnabled && (
            <DisabledVideoOverlay data-testid="disabled-remote-video">
              <VideocamOff />
              <strong>Participant has disabled their video.</strong>
            </DisabledVideoOverlay>
          )}

          <audio autoPlay={true} data-testid="remote-audio" ref={audioRef} />
        </div>
      ) : (
        <div className="hidden" key={`${sid}-audio-container`}>
          <audio
            autoPlay={true}
            data-testid="remote-audio"
            key={`${sid}-audio`}
            ref={audioRef}
          />
        </div>
      )}
    </Fragment>
  );
};

export default RemoteParticipant;
