import { isSupported } from "twilio-video";
import {
  browserName,
  deviceType,
  fullBrowserVersion,
  osName,
  osVersion,
} from "react-device-detect";

export interface DeviceInfo {
  browserName: string;
  browserVersion: string;
  browser: {
    name: string;
    version: string;
  };
  device: {
    type: string;
  };
  os: {
    name: string;
    version: string;
  };
  supportsFaceTime: boolean;
  supportsWebRTC: boolean;
  /**
   * @deprecated Please use supportsWebRTC instead.
   */
  webRTCcompatible: boolean;
}

const osNameTranslations: Record<string, string> = {
  Android: "android",
  iOS: "ios",
  Linux: "linux",
  "Mac OS": "macos",
  Windows: "windows",
};

export const determineFaceTimeSupport = (): boolean => {
  return osName === "iOS" || osName === "Mac OS";
};

export const getDeviceInfo = (): DeviceInfo => ({
  browserName,
  browserVersion: fullBrowserVersion,
  browser: {
    name: browserName,
    version: fullBrowserVersion,
  },
  device: {
    type: deviceType === "browser" ? "desktop" : deviceType,
  },
  os: {
    name: osNameTranslations[osName] ?? osName,
    version: osVersion,
  },
  supportsFaceTime: determineFaceTimeSupport(),
  supportsWebRTC: isSupported,
  webRTCcompatible: isSupported,
});
