import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import makeStyles from "@mui/styles/makeStyles";
import VideocamOff from "@mui/icons-material/VideocamOff";
import ZoomInMap from "@mui/icons-material/ZoomInMap";
import ZoomOutMap from "@mui/icons-material/ZoomOutMap";
import {
  LocalAudioTrackPublication,
  LocalParticipant as VideoLocalParticipant,
  LocalTrack,
  LocalVideoTrackPublication,
} from "twilio-video";
import { useTracks } from "../../hooks/useTracks";
import { trackPubsToTracks } from "../../helpers";
import { CustomLocalParticipantEvent } from "../../constants";

const useStyles = makeStyles((theme: any) => ({
  disabledVideo: {
    alignItems: "center",
    backgroundColor: "black",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 30,
    "& > *": {
      color: "#FFFFFF",
    },
  },
  enabledVideo: {
    backgroundColor: "transparent",
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 30,
  },
}));

type Props = {
  displayLocalParticipant: boolean;
  enlargePreview: boolean;
  localParticipant: VideoLocalParticipant;
  setEnlargePreview: (newEnlargePreview: boolean) => void;
};

const LocalParticipant: FunctionComponent<Props> = (props) => {
  const {
    displayLocalParticipant = true,
    enlargePreview,
    localParticipant,
  } = props;

  const classes = useStyles();
  const videoRef = useRef<HTMLVideoElement>(null);

  const [videoTrack, setVideoTrack] = useState(
    trackPubsToTracks(localParticipant.videoTracks)[0]
  );

  useEffect(() => {
    if (videoTrack) {
      videoTrack.attach(videoRef.current as HTMLMediaElement);

      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTrack]);

  useEffect(() => {
    if (localParticipant) {
      const handleLocalTrackChanged = (
        trackPub: LocalAudioTrackPublication | LocalVideoTrackPublication
      ) => {
        if (trackPub.kind === "video") {
          setVideoTrack(trackPub.track);
        }
      };

      localParticipant.on(
        CustomLocalParticipantEvent.LocalTrackChange,
        handleLocalTrackChanged
      );

      /*return () => {
        console.log("Stopping audio and video input tracks");
        const audioTracks = trackPubsToTracks(localParticipant.audioTracks);
        audioTracks.forEach((track: any) => track.stop());

        const videoTracks = trackPubsToTracks(localParticipant.videoTracks);
        videoTracks.forEach((track: any) => track.stop());

        console.log("Unpublishing audio and video tracks");
        localParticipant.unpublishTracks(
          audioTracks as unknown as LocalTrack[]
        );
        localParticipant.unpublishTracks(
          videoTracks as unknown as LocalTrack[]
        );

        console.log("Removing track change listener");
        localParticipant.off(
          CustomLocalParticipantEvent.LocalTrackChange,
          handleLocalTrackChanged
        );
      };*/
    }
  }, [localParticipant]);

  const { isVideoEnabled } = useTracks(localParticipant, true);

  const shrinkPreview = () => {
    props.setEnlargePreview(false);
  };

  const growPreview = () => {
    props.setEnlargePreview(true);
  };

  const handleToggleClick = () => {
    if (enlargePreview) {
      shrinkPreview();
    } else {
      growPreview();
    }
  };

  useEffect(() => {
    if (!displayLocalParticipant || !isVideoEnabled) {
      shrinkPreview();
    }
  }, [displayLocalParticipant, isVideoEnabled, shrinkPreview]);

  const iconSize = isMobile ? "medium" : "large";

  const defaultVideoPreviewClassName = isMobile
    ? "local-participant-video-container-mobile"
    : "local-participant-video-container-normal";

  const videoPreviewClassName = displayLocalParticipant
    ? enlargePreview
      ? "local-participant-video-container-large"
      : defaultVideoPreviewClassName
    : "hidden";

  return (
    <div className={"local-participant-container"}>
      <div className={videoPreviewClassName}>
        <video
          autoPlay
          data-testid="local-video"
          id="local-participant-video"
          muted
          playsInline
          ref={videoRef}
        />

        {!isVideoEnabled && (
          <div className={classes.disabledVideo}>
            <div>
              <VideocamOff fontSize={iconSize} />
            </div>
            <div>
              <p>You have disabled your camera.</p>
            </div>
          </div>
        )}

        {isVideoEnabled && (
          <div className={classes.enabledVideo}>
            {enlargePreview ? (
              <ZoomInMap
                fontSize="large"
                style={{
                  border: "1.5px solid white",
                  backgroundColor: "black",
                  color: "white",
                  margin: "1.5px",
                }}
                onClick={handleToggleClick}
              />
            ) : (
              <ZoomOutMap
                fontSize="large"
                style={{
                  border: "1.5px solid white",
                  backgroundColor: "black",
                  color: "white",
                  margin: "1.5px",
                }}
                onClick={handleToggleClick}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocalParticipant;
