import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { ConnectOptions, Logger } from "twilio-video";
import Toolbox from "./Toolbox";
import LocalParticipant from "./LocalParticipant";
import RemoteParticipant from "./RemoteParticipant";
import { Perspective } from "../../types";
import { determineShouldRetryConnection } from "../../helpers";
import { messaging } from "../../constants";
import {
  useParticipants,
  usePrevious,
  useRecommendedConnectionProfile,
  useRoom,
} from "../../hooks";

const retryInMillis = 5000;
const logger = Logger.getLogger("twilio-video");
logger.setLevel("info");

type Props = {
  accessToken: string;
  connectOptions?: ConnectOptions;
  noCmp?: boolean;
  perspective: Perspective;
  remoteParticipantVolume: number;
  remotePresenterVolume: number;
  roomName: string;
};

const Room: React.FC<Props> = (props) => {
  const {
    accessToken,
    connectOptions,
    noCmp = false,
    remoteParticipantVolume,
    remotePresenterVolume,
    roomName,
    perspective,
  } = props;

  const previousAccessToken = usePrevious(accessToken);

  if (connectOptions) {
    console.log(
      "connectOptions were received but are being ignored as of 01/26/2023"
    );
    console.dir(connectOptions);
  }

  /*const connectionProfile =
    connectOptions ??
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRecommendedConnectionProfile(perspective, "presentation");*/
  const connectionProfile = useRecommendedConnectionProfile(
    perspective,
    "presentation"
  );

  const { connect, connectionError, disconnect, isConnecting, room } =
    useRoom(connectionProfile);

  const { participants, presenterParticipant } = useParticipants(room);

  const shouldRetryConnection = connectionError
    ? determineShouldRetryConnection(connectionError)
    : false;
  const shouldConnect = !room && (!connectionError || shouldRetryConnection);
  const shouldReconnect =
    room && previousAccessToken && accessToken !== previousAccessToken;

  useEffect(() => {
    const hookName = "Room connection effect hook";
    console.log(`${hookName}: triggered.`);

    if (
      (shouldConnect || shouldReconnect) &&
      !isConnecting &&
      accessToken &&
      roomName
    ) {
      if (shouldConnect) {
        if (shouldRetryConnection) {
          const timeout = setTimeout(() => {
            console.log(`${hookName}: invoking connect (timeout).`);
            connect(accessToken, roomName);
            clearTimeout(timeout);
          }, retryInMillis);
        } else {
          console.log(`${hookName}: invoking connect.`);
          connect(accessToken, roomName);
        }
      } else {
        // This will retrigger the effect with room being falsy, thus the invoking call to connect above
        console.log(`${hookName}: invoking disconnect to retrigger hook.`);
        disconnect();
      }
    }
  }, [
    accessToken,
    connect,
    disconnect,
    isConnecting,
    roomName,
    shouldConnect,
    shouldRetryConnection,
    shouldReconnect,
  ]);

  useEffect(() => {
    const hookName = "Room watching effect hook";
    console.log(`${hookName}: triggered.`);

    if (room) {
      return () => {
        console.log(`${hookName}: invoking disconnect from cleanup function.`);

        disconnect();
      };
    } else {
      return () => {
        console.log(`${hookName}: empty cleanup function was called.`);
      };
    }
  }, [disconnect, room]);

  const messageStyle: React.CSSProperties = {
    color: "lightgrey",
  };

  const hasErrors = Boolean(connectionError?.message);

  const [displayLocalParticipant, setDisplayLocalParticipant] =
    useState<boolean>(true);

  const setLocalParticipant = (newDisplayLocalParticipant: boolean) => {
    setDisplayLocalParticipant(newDisplayLocalParticipant);
  };

  const [enlargePreview, setEnlargePreview] = useState<boolean>(false);

  // Suppressed 4/12/2023
  /*// Assumes 1080 output on mac mini
  const cropShim = {
    x: 8,
    y: 98,
    height: 977,
    width: 1736,
  }; // TODO: Retrieve from Queue Server

  const [windowSize, setWindowSize] = useState({
    w: window.innerWidth,
    h: window.innerHeight,
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ w: window.innerWidth, h: window.innerHeight });
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const cropAdjustedContainer: React.CSSProperties = {
    height: `${cropShim.height ?? 1080}px`,
    left: cropShim.x ?? 0,
    overflow: "hidden",
    position: "absolute",
    top: cropShim.y - (1080 - windowSize.h) ?? 0,
    width: `${cropShim.width ?? 1920}px`,
  };*/

  // Hardcoded 4/12/2023.  This area exceeds the currently defined "crop" by a few pixels in all directions
  const roomContainerWithBleed: React.CSSProperties = {
    height: 987,
    left: 0,
    overflow: "hidden",
    position: "absolute",
    top: 10,
    width: 1754,
  };

  return (
    <div
      className={
        perspective === Perspective.participant
          ? "video-room-container"
          : noCmp
          ? "fullscreen"
          : undefined
      }
      id="video-embed-room"
      style={
        perspective !== Perspective.participant && !noCmp
          ? roomContainerWithBleed
          : undefined
      }
    >
      {!room && (!hasErrors || shouldRetryConnection) && (
        <div className="connecting-container">
          <strong style={messageStyle}>{messaging.Connecting}</strong>
          <CircularProgress />
        </div>
      )}
      {!room && hasErrors && !shouldRetryConnection && (
        <strong style={messageStyle}>{connectionError?.message}</strong>
      )}
      {room?.localParticipant &&
        (perspective === Perspective.participant || noCmp) && (
          <LocalParticipant
            displayLocalParticipant={displayLocalParticipant}
            enlargePreview={enlargePreview}
            key={room.localParticipant.sid}
            localParticipant={room.localParticipant}
            setEnlargePreview={setEnlargePreview}
          />
        )}
      {perspective === Perspective.participant && presenterParticipant && (
        <RemoteParticipant
          enlargePreview={enlargePreview}
          isPresenter={true}
          key={presenterParticipant.sid}
          noCmp={noCmp}
          participant={presenterParticipant}
          perspective={perspective}
          volume={remotePresenterVolume}
        />
      )}
      {participants
        .filter((participant) => participant.identity.startsWith("participant"))
        .map((participant) => (
          <RemoteParticipant
            enlargePreview={enlargePreview}
            key={participant.sid}
            participant={participant}
            perspective={perspective}
            volume={remoteParticipantVolume}
          />
        ))}
      {room?.localParticipant &&
        (perspective === Perspective.participant || noCmp) && (
          <Toolbox
            displayLocalParticipant={displayLocalParticipant}
            localParticipant={room.localParticipant}
            setLocalParticipant={setLocalParticipant}
          />
        )}
      {room &&
        perspective === Perspective.presenter &&
        participants.length < 1 && (
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "#000000",
              color: "#FFFFFF",
              display: "flex",
              height: "100%",
              justifyContent: "center",
              position: "absolute",
              width: "100%",
              "& > *": {
                marginRight: 5,
              },
            }}
          >
            <CircularProgress />
            <Typography variant="h4">Waiting for Participant...</Typography>
          </Box>
        )}
    </div>
  );
};

export default Room;
