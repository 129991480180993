import React, { Fragment, MouseEventHandler, useState } from "react";
import { Badge, Button, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Mic as MicIcon,
  MicOff as MicOffIcon,
  Person as PreviewOnIcon,
  PersonOff as PreviewOffIcon,
  Videocam as VideocamIcon,
  VideocamOff as VideocamOffIcon,
  VolumeUp as VolumeIcon,
  VolumeOff as VolumeOffIcon,
} from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import { LocalParticipant } from "twilio-video";
import { sinkIdCompatible } from "../../constants";
import DeviceSelect from "./DeviceSelect";
import { useDevices } from "../../hooks/useDevices";
import { useAudioOutput } from "../../hooks";

const useStyles = makeStyles((theme: any) => ({
  toolbox: {
    alignItems: "center",
    bottom: "5px",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    maxWidth: "100%",
    opacity: isMobile ? "0.8" : "0.7",
    position: "absolute",
    transition: "opacity 0.5s",
    width: "100%",
    zIndex: 25,
    "&:hover": { opacity: "1.0" },
  },
  toolboxBtnBadge: {
    height: "30px",
    overflow: "hidden",
    right: "2.5px",
    top: "5px",
    width: "30px",
  },
  toolboxBtnGroup: {
    backgroundColor: "#131519",
    borderRadius: "4px",
    paddingBottom: "6.5px",
    paddingLeft: "13px",
    paddingRight: "13px",
    paddingTop: "6.5px",
  },
  toolboxBtnOff: {
    backgroundColor: "red",
    color: "#ffffff",
    height: 40,
    marginLeft: "2.5",
    marginRight: "2.5",
    width: 20,
    "&:hover": { backgroundColor: "#CD5C5C", color: "#ffffff" },
    "&:disabled": { backgroundColor: "red", color: "#ffffff" },
    "&.Mui-disabled": {
      pointerEvents: "auto",
      backgroundColor: "red",
      color: "#ffffff",
    },
  },
  toolboxBtnOn: {
    backgroundColor: "#131519",
    color: "#ffffff",
    height: 40,
    marginLeft: "2.5",
    marginRight: "2.5",
    width: 20,
    "&:hover": { backgroundColor: "#131519" },
    "&:disabled": { backgroundColor: "red", color: "#ffffff" },
    "&.Mui-disabled": {
      pointerEvents: "auto",
      backgroundColor: "red",
      color: "#ffffff",
    },
  },
}));

export type Props = {
  displayLocalParticipant: boolean;
  localParticipant: LocalParticipant;
  setLocalParticipant: (newDisplayLocalParticipant: boolean) => void;
};

const Controls: React.FC<Props> = (props) => {
  const { displayLocalParticipant, localParticipant } = props;

  const classes = useStyles();

  const { audioInputDevices, audioOutputDevices, videoInputDevices } =
    useDevices();
  const { muted: audioOutputMuted, setMuted: setAudioOutputMuted } =
    useAudioOutput();

  const [shouldAudioBeEnabled, setShouldAudioBeEnabled] =
    useState<boolean>(true);
  const [shouldVideoBeEnabled, setShouldVideoBeEnabled] =
    useState<boolean>(true);

  const [toggleCameraLockout, setToggleCameraLockout] =
    useState<boolean>(false);

  const [togglePreviewLockout, setTogglePreviewLockout] =
    useState<boolean>(false);

  const handleAudioOutputButtonClick: MouseEventHandler<
    HTMLButtonElement
  > = () => {
    setAudioOutputMuted(!audioOutputMuted);
  };

  const handleAudioButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    setShouldAudioBeEnabled((shouldAudioBeEnabled: boolean) => {
      const res = !shouldAudioBeEnabled;

      localParticipant.audioTracks.forEach(({ track }) =>
        res ? track.enable() : track.disable()
      );

      return res;
    });
  };

  const handleVideoButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    setToggleCameraLockout(true);
    if (shouldVideoBeEnabled) {
      props.setLocalParticipant(true); // Force the preview window to be open if user is disabling their camera
    }
    setShouldVideoBeEnabled((shouldVideoBeEnabled: boolean) => {
      const res = !shouldVideoBeEnabled;

      localParticipant.videoTracks.forEach(({ track }) =>
        res ? track.enable() : track.disable()
      );

      return res;
    });
    const lockout = setTimeout(() => {
      setToggleCameraLockout(false);
      clearTimeout(lockout);
    }, 750);
  };

  const handlePreviewButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    setTogglePreviewLockout(true);
    const newDisplayLocalParticipant = !displayLocalParticipant;
    props.setLocalParticipant(newDisplayLocalParticipant);
    const lockout = setTimeout(() => {
      setTogglePreviewLockout(false);
      clearTimeout(lockout);
    }, 750);
  };

  const togglePreviewIsDisabled = !shouldVideoBeEnabled; // Prevent toggling of preview window if the participant has disabled their camera

  return (
    <Fragment>
      <div className={classes.toolbox}>
        <div className={classes.toolboxBtnGroup}>
          <Tooltip
            placement="top"
            title={
              togglePreviewIsDisabled
                ? "You cannot disable your Video Preview while the camera is disabled. "
                : `${
                    displayLocalParticipant ? "Hide" : "Show"
                  } your Video Preview`
            }
          >
            <Button
              className={
                displayLocalParticipant &&
                (!togglePreviewIsDisabled || !togglePreviewLockout)
                  ? classes.toolboxBtnOn
                  : classes.toolboxBtnOff
              }
              data-testid="toolbox-localparticipant-preview-enable-button"
              disabled={togglePreviewLockout || togglePreviewIsDisabled}
              variant="contained"
              onClick={handlePreviewButtonClick}
            >
              {displayLocalParticipant ? <PreviewOnIcon /> : <PreviewOffIcon />}
            </Button>
          </Tooltip>

          {sinkIdCompatible && (
            <Badge
              badgeContent={
                <DeviceSelect
                  devices={audioOutputDevices}
                  disabled={audioOutputMuted}
                  kind="audiooutput"
                  localParticipant={localParticipant}
                  tooltipTitle="Audio Output Settings"
                />
              }
              classes={{
                badge: classes.toolboxBtnBadge,
              }}
            >
              <Tooltip
                placement="top"
                title={`${audioOutputMuted ? "Unmute" : "Mute"} Audio Output`}
              >
                <Button
                  className={
                    !audioOutputMuted
                      ? classes.toolboxBtnOn
                      : classes.toolboxBtnOff
                  }
                  data-testid="toolbox-audio-ouput-enable-button"
                  variant="contained"
                  onClick={handleAudioOutputButtonClick}
                >
                  {audioOutputMuted ? <VolumeOffIcon /> : <VolumeIcon />}
                </Button>
              </Tooltip>
            </Badge>
          )}

          <Badge
            badgeContent={
              <DeviceSelect
                devices={audioInputDevices}
                disabled={!shouldAudioBeEnabled}
                kind="audioinput"
                localParticipant={localParticipant}
                tooltipTitle="Audio Input Settings"
              />
            }
            classes={{
              badge: classes.toolboxBtnBadge,
            }}
          >
            <Tooltip
              placement="top"
              title={`${shouldAudioBeEnabled ? "Mute" : "Unmute"} Microphone`}
            >
              <Button
                className={
                  shouldAudioBeEnabled
                    ? classes.toolboxBtnOn
                    : classes.toolboxBtnOff
                }
                data-testid="toolbox-audio-input-enable-button"
                variant="contained"
                onClick={handleAudioButtonClick}
              >
                {shouldAudioBeEnabled ? <MicIcon /> : <MicOffIcon />}
              </Button>
            </Tooltip>
          </Badge>

          <Badge
            badgeContent={
              <DeviceSelect
                devices={videoInputDevices}
                disabled={toggleCameraLockout || !shouldVideoBeEnabled}
                kind="videoinput"
                localParticipant={localParticipant}
                tooltipTitle="Video Settings"
              />
            }
            classes={{
              badge: classes.toolboxBtnBadge,
            }}
          >
            <Tooltip
              placement="top"
              title={`${shouldVideoBeEnabled ? "Stop" : "Start"} Camera`}
            >
              <Button
                className={
                  !shouldVideoBeEnabled || toggleCameraLockout
                    ? classes.toolboxBtnOff
                    : classes.toolboxBtnOn
                }
                data-testid="toolbox-video-enable-button"
                disabled={toggleCameraLockout}
                variant="contained"
                onClick={handleVideoButtonClick}
              >
                {shouldVideoBeEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
              </Button>
            </Tooltip>
          </Badge>
        </div>
      </div>
    </Fragment>
  );
};

export default Controls;
