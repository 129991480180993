import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";

const DEFAULT_ENVIRONMENT_NAME = "local-dev";

export function initSentryConfiguration(): boolean {
  const {
    REACT_APP_SENTRY_DSN,
    REACT_APP_PACKAGE_VERSION,
    REACT_APP_PACKAGE_NAME,
    REACT_APP_ENVIRONMENT_NAME,
    REACT_APP_SENTRY_LOG_LEVELS,
  } = process.env;
  if (
    !(
      REACT_APP_SENTRY_DSN &&
      REACT_APP_PACKAGE_NAME &&
      REACT_APP_PACKAGE_VERSION
    )
  ) {
    console.error("Sentry was not initialized due to misconfiguration.");
    return false;
  }
  try {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      environment: REACT_APP_ENVIRONMENT_NAME ?? DEFAULT_ENVIRONMENT_NAME,
      integrations: [
        new Integrations.BrowserTracing(),
        new CaptureConsole({
          levels: REACT_APP_SENTRY_LOG_LEVELS?.split(",") ?? ["error"],
        }),
      ],
      normalizeDepth: 5,
      release: `${REACT_APP_PACKAGE_NAME}@${REACT_APP_PACKAGE_VERSION}`,
      tracesSampleRate: 1.0,
    });
    console.info("Sentry has been initialized.");
  } catch (error) {
    console.error(error.message);
    return false;
  }
  return true;
}
