import type { LocalTrack, TwilioError } from "twilio-video";

import { useCallback, useEffect, useRef, useState } from "react";
import { ConnectOptions, Room, connect } from "twilio-video";
import { messaging } from "../constants";
import { trackPubsToTracks } from "../helpers";

export const useRoom = (options?: ConnectOptions) => {
  const [connectionError, setConnectionError] = useState<TwilioError | null>(
    null
  );
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [room, setRoom] = useState<Room | null>(null);

  const optionsRef = useRef<ConnectOptions>(options ?? {});

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object.
    optionsRef.current = options ?? {};
  }, [options]);

  const connectToRoom = useCallback(
    async (accessToken: string, roomName: string) => {
      setIsConnecting(true);

      try {
        console.log(`Connecting to room with options:`);
        console.dir(optionsRef.current);

        const room = await connect(accessToken, {
          ...optionsRef.current,
          automaticSubscription: false,
          name: roomName,
        });

        setRoom(room);
        setConnectionError(null);
      } catch (err: any) {
        let errorToSet: TwilioError;

        if (err) {
          errorToSet = err;
          errorToSet.message = `${messaging.ConnectionErrorPrefix}${err.message}`;
        } else {
          const errorObj = new Error(
            `${messaging.ConnectionErrorPrefix}${"Unknown"}`
          );

          Object.defineProperty(errorObj, "code", { value: -1 });

          errorToSet = errorObj as TwilioError;
        }

        console.warn(errorToSet);
        setConnectionError(errorToSet);
      }

      setIsConnecting(false);
    },
    []
  );

  const disconnect = useCallback(() => {
    console.log("Got disconnect command");

    if (room) {
      const { localParticipant } = room;
      console.log("Stopping audio and video input tracks");
      const audioTracks = trackPubsToTracks(localParticipant.audioTracks);
      audioTracks.forEach((track: any) => track.stop());

      const videoTracks = trackPubsToTracks(localParticipant.videoTracks);
      videoTracks.forEach((track: any) => track.stop());

      console.log("Unpublishing audio and video tracks");
      localParticipant.unpublishTracks(audioTracks as unknown as LocalTrack[]);
      localParticipant.unpublishTracks(videoTracks as unknown as LocalTrack[]);

      /*console.log("Removing track change listener");
      localParticipant.off(
        CustomLocalParticipantEvent.LocalTrackChange,
        handleLocalTrackChanged
      );*/

      console.log("Tearing down room");
      room.removeAllListeners();
      room.disconnect();

      setRoom(null);
    }
  }, [room]);

  return {
    connect: connectToRoom,
    connectionError,
    disconnect,
    isConnecting,
    room,
  };
};
