import type {
  PreflightOptions,
  PreflightTest,
  PreflightTestReport,
} from "twilio-video";

import { useEffect, useState } from "react";
import {
  DiagnosticError,
  MediaConnectionBitrateTest,
  testMediaConnectionBitrate,
} from "@twilio/rtc-diagnostics";
import { runPreflight as testPreflight } from "twilio-video";
import { useMemoCompare } from "./hooks";

export interface NetworkDiagnosticOptions {
  bitrate: MediaConnectionBitrateTest.Options;
  prefight?: PreflightOptions;
}

export type NetworkDiagnosticMeasurement = number | null;

/**
 * Represents RTC related stats that were observed during preflight test
 */
export interface NetworkDiagnosticReport {
  /**
   * Bitrate in bits per second. Either downlink or uplink bandwidth, whichever was lowest.
   */
  bitrate: NetworkDiagnosticMeasurement;
  /**
   * Packets delay variation on audio tracks
   */
  jitter: NetworkDiagnosticMeasurement;
  /**
   * Packet loss as a percent of total packets sent
   */
  packetLoss: NetworkDiagnosticMeasurement;
  /**
   * Round trip time, to the server back to the client in milliseconds
   */
  rtt: NetworkDiagnosticMeasurement;
}

export const useNetworkDiagnostic = (
  token: string,
  options: NetworkDiagnosticOptions
) => {
  const { prefight: preflightOptions } = options;

  const bitrateOptions = useMemoCompare(options.bitrate);
  const [error, setError] = useState<Error | null>(null);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [fullReport, setFullReport] = useState<NetworkDiagnosticReport | null>(
    null
  );

  const [bitrateTest, setBitrateTest] =
    useState<MediaConnectionBitrateTest | null>(null);
  const [preflightTest, setPreflightTest] = useState<PreflightTest | null>(
    null
  );

  const [bitrateTestReport, setBitrateTestReport] =
    useState<MediaConnectionBitrateTest.Report | null>(null);
  const [preflightTestReport, setPreflightTestReport] =
    useState<PreflightTestReport | null>(null);

  useEffect(() => {
    // rerun test anytime token changes
    if (bitrateOptions && token) {
      try {
        console.log("Starting network diagnostic...");

        setBitrateTest(testMediaConnectionBitrate(bitrateOptions));

        setError(null);
        setIsRunning(true);
        setBitrateTestReport(null);
        setPreflightTestReport(null);
        setFullReport(null);
      } catch (err) {
        console.warn("Network diagnostic failed to start.", err);
        setIsRunning(false);
        setError(err);
      }
    }
  }, [bitrateOptions, token]);

  useEffect(() => {
    if (bitrateTest) {
      const handleBitrate = (bitrate: number) => {
        console.log(`Received bitrate value: ${bitrate}`);
      };
      const handleError = (error: DiagnosticError) => {
        console.warn("Bitrate test failed.", error);
        setError(error);
        setIsRunning(false);
      };
      const handleEnd = (report: MediaConnectionBitrateTest.Report) => {
        console.log("Bitrate test completed.", report);
        setBitrateTestReport(report);
      };

      bitrateTest.on(MediaConnectionBitrateTest.Events.Bitrate, handleBitrate);
      bitrateTest.on(MediaConnectionBitrateTest.Events.Error, handleError);
      bitrateTest.on(MediaConnectionBitrateTest.Events.End, handleEnd);

      const timeout = setTimeout(() => {
        console.log("Stopping bitrate test...");
        bitrateTest?.stop();
      }, 10000);

      return () => {
        console.log("Cleaning up bitrate test.");

        clearTimeout(timeout);

        bitrateTest?.removeAllListeners(
          MediaConnectionBitrateTest.Events.Bitrate
        );
        bitrateTest?.removeAllListeners(
          MediaConnectionBitrateTest.Events.Error
        );
        bitrateTest?.removeAllListeners(MediaConnectionBitrateTest.Events.End);
        bitrateTest?.stop?.();
      };
    }
  }, [bitrateTest]);

  useEffect(() => {
    if (preflightTest) {
      const handleCompleted = (report: PreflightTestReport) => {
        console.log("Preflight test completed.", report);
        setPreflightTestReport(report);
      };
      const handleFailure = (error: any) => {
        console.warn("Preflight test failed.", error);
        setError(error);
        setPreflightTest(null);
        setIsRunning(false);
      };

      preflightTest.on("completed", handleCompleted);
      preflightTest.on("failed", handleFailure);

      return () => {
        console.log("Cleaning up preflight test.");

        preflightTest?.removeAllListeners("completed");
        preflightTest?.removeAllListeners("failed");
        preflightTest?.stop?.();
      };
    }
  }, [preflightTest]);

  useEffect(() => {
    if (bitrateTestReport && !preflightTestReport) {
      try {
        setBitrateTest(null);
        setPreflightTest(testPreflight(token, preflightOptions));
      } catch (err) {
        console.warn("Preflight test failed to start.", err);
        setError(err);
        setIsRunning(false);
      }
    } else if (bitrateTestReport && preflightTestReport) {
      const { averageBitrate } = bitrateTestReport;
      const {
        stats: { jitter, packetLoss, rtt },
      } = preflightTestReport;

      setPreflightTest(null);
      setIsRunning(false);
      setBitrateTestReport(null);
      setPreflightTestReport(null);
      setFullReport({
        bitrate: averageBitrate ?? null,
        jitter: jitter?.average ?? null,
        packetLoss: packetLoss?.average ?? null,
        rtt: rtt?.average ?? null,
      });
    }
  }, [bitrateTestReport, preflightTestReport, preflightOptions, token]);

  return {
    isRunning,
    error,
    report: fullReport,
  };
};
